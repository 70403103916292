export const ENV = {
  VERSION: '1.0',
  API_VERSION: '2.0',
  PUBLIC_STRIPE_KEY: 'pk_test_t1WHSW3dlbCyWaJe8vStOrxf00Gl3m9aWM',
  ENDPOINT: 'https://api-sandbox.youbumerang.com',
  CURRENT_ENV: 'sandbox',
  CRISP_ID: 'ed9a8769-ce9b-402c-849d-02d52da57337',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBTjxOuFMQ5di7Cz24GzPDF28wBBUyKKQA',
  FIREBASE_SENDER_ID: '191951374251',
  SEGMENT_WRITE_KEY: '2FlcYL7kAP2mnzlJ2sDmJLMO9vl39RSd',
  AIRTABLE_API_KEY: 'keyHNAlTsIIIX6BIo',
  STRIPE_ACCOUNT_ID: 'acct_1FKnueF03L74ac1E',
  FIREBASE_API_KEY:
    'eyJhcGlLZXkiOiJBSXphU3lDNjNwV2pkUG1zWTM4MUhIdkZuR1pyRVc4bVlER3F3UUkiLCJhdXRoRG9tYWluIjoiYnVtZXJhbmctYXBwLXNhbmRib3guZmlyZWJhc2VhcHAuY29tIiwiZGF0YWJhc2VVUkwiOiJodHRwczovL2J1bWVyYW5nLWFwcC1zYW5kYm94LWRlZmF1bHQtcnRkYi5ldXJvcGUtd2VzdDEuZmlyZWJhc2VkYXRhYmFzZS5hcHAiLCJwcm9qZWN0SWQiOiJidW1lcmFuZy1hcHAtc2FuZGJveCIsInN0b3JhZ2VCdWNrZXQiOiJidW1lcmFuZy1hcHAtc2FuZGJveC5hcHBzcG90LmNvbSIsIm1lc3NhZ2luZ1NlbmRlcklkIjoiNzY5MzEyNzgzODA4IiwiYXBwSWQiOiIxOjc2OTMxMjc4MzgwODp3ZWI6NjMxYzQwZGZmYzgzNmIxY2M3ZGMyNSIsIm1lYXN1cmVtZW50SWQiOiJHLUJNMjVYSDQ4MlQifQo='
};
